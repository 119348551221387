'use client'

import { type AxiosError, type AxiosResponse } from 'axios'
import useSWRMutation, { type SWRMutationResponse } from 'swr/mutation'

import { ReavailabilityNotificationControllerApiFactory } from '@redteclab/api/clients/bully'

import {
  buildClientSideBullyRequestConfig,
  useBullyApi,
} from '../../../base-api-communication'
import { useGlobalConfigContext } from '../../../global-config'

const getUserApiSwrSubscribeToEmailOnProductAvailabilityMutationKey =
  (): string => {
    return 'useUserApiLoginMutation'
  }

export const useApiSwrReavailabilityNotificationSubscribeUpidMutation = ({
  onError,
  onSuccess,
}: {
  onError?: () => void
  onSuccess?: () => void
} = {}): SWRMutationResponse<
  AxiosResponse,
  AxiosError,
  string,
  { productId: string }
> => {
  const api = useBullyApi(ReavailabilityNotificationControllerApiFactory)
  const globalConfig = useGlobalConfigContext()

  const makeCallToSubscribeEmailOnProductAvailability = async (
    swrKey: string,
    data: { arg: { productId: string } },
  ): Promise<AxiosResponse> => {
    return api.reavailabilityV1TenantSubscribeUpidPost(
      {
        tenant: globalConfig.tenantAndEnv,
        upid: data.arg.productId,
      },
      buildClientSideBullyRequestConfig(globalConfig),
    )
  }

  return useSWRMutation(
    getUserApiSwrSubscribeToEmailOnProductAvailabilityMutationKey(),
    makeCallToSubscribeEmailOnProductAvailability,
    {
      onError,
      onSuccess: () => {
        onSuccess?.()
      },
    },
  )
}
