'use client'

import { type AxiosError } from 'axios'
import useSwr, { type SWRResponse } from 'swr'

import {
  AccountControllerApiFactory,
  type SuccessGetAccountCustomer,
} from '@redteclab/api/clients/bully'

import {
  buildClientSideBullyRequestConfig,
  useBullyApi,
} from '../../../base-api-communication'
import { useGlobalConfigContext } from '../../../global-config'

const SWR_KEY = 'userCustomerDataGet'

export const useApiSwrGetCustomerData = (): SWRResponse<
  SuccessGetAccountCustomer,
  AxiosError
> => {
  const config = useGlobalConfigContext()
  const client = useBullyApi(AccountControllerApiFactory)
  const fetcher = async (): Promise<SuccessGetAccountCustomer> => {
    const response = await client.accountV1TenantUserGet(
      {
        tenant: config.tenantAndEnv,
      },
      buildClientSideBullyRequestConfig(config),
    )

    return response.data
  }

  return useSwr(SWR_KEY, fetcher)
}
