import { type CaptureErrorOptions } from 'elastic-apm-node'

import { apmGetAxiosErrorCustomContext } from './apmGetAxiosErrorCustomContext'
import { apmIsAxiosError } from './apmIsAxiosError'

const captureErrorInstance = (error: Error): void => {
  if (globalThis.serverSideApmInstance) {
    const options: CaptureErrorOptions = {}

    if (apmIsAxiosError(error)) {
      options.custom = apmGetAxiosErrorCustomContext(error)
    }

    globalThis.serverSideApmInstance.captureError(error, options)
  } else if (globalThis.clientSideApmInstance) {
    globalThis.clientSideApmInstance.captureError(error)
  } else {
    if (apmIsAxiosError(error)) {
      // clean up error to reduce huge payload in console
      delete error.config
      delete error.request
      delete error.response
    }

    // eslint-disable-next-line no-console -- debugging purpose during development
    console.error(error)
  }
}

export const apmCaptureError = (
  options:
    | Error
    | {
        message: string
        name: string
      },
): void => {
  if (options instanceof Error) {
    captureErrorInstance(options)

    return
  }

  const errorInstance = new Error(options.message)
  errorInstance.name = options.name
  errorInstance.message = options.message

  captureErrorInstance(errorInstance)
}
